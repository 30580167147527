@import '../../../gantt-master/src/gantt.scss';

.gantt {
    .grid-background {
        fill: none;
    }
    .grid-header {
        fill: rgba(var(--fuse-bg-card-rgb), var(--tw-bg-opacity)) !important;
        stroke: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
        stroke-width: 1.4;
    }
    .grid-row {
        fill: rgba(var(--fuse-bg-card-rgb)) !important;
        .dark & {
            fill: rgba(var(--fuse-bg-default-rgb)) !important;
        }
    }
    .grid-row:nth-child(even) {
        fill: rgba(var(--fuse-bg-default-rgb)) !important;
        .dark & {
            fill: rgba(var(--fuse-bg-card-rgb)) !important;
        }
    }
    .row-line {
        stroke: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
    }
    .tick {
        stroke: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
        stroke-width: 0.2;
        &.thick {
            stroke-width: 0.4;
        }
    }
    .today-highlight {
        fill: $light-yellow;
        opacity: 0.5;
    }

    .arrow {
        fill: none;
        stroke: var(--fuse-text-default) !important;
        stroke-width: 1.4;
    }

    .bar {
        fill: rgba(var(--fuse-text-secondary-rgb), 0.25);
        stroke: $bar-stroke;
        stroke-width: 0;
        transition: stroke-width 0.3s ease;
        user-select: none;
    }
    .bar-progress {
        fill: var(--fuse-primary) !important;
    }
    .bar-invalid {
        fill: transparent;
        stroke: $bar-stroke;
        stroke-width: 1;
        stroke-dasharray: 5;

        & ~ .bar-label {
            fill: var(--fuse-text-default) !important;
        }
    }
    .bar-label {
        fill: #fff;
        dominant-baseline: central;
        text-anchor: middle;
        font-size: 12px;
        font-weight: 500 !important;

        &.big {
            fill: var(--fuse-text-default) !important;
            text-anchor: start;
        }
    }

    .handle {
        fill: $handle-color;
        cursor: ew-resize;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease;
    }

    .bar-wrapper {
        cursor: pointer;
        outline: none;

        &:hover {
            .bar {
                fill: rgba(var(--fuse-text-secondary-rgb), var(--tw-text-opacity));
            }

            .bar-progress {
                fill: var(--fuse-primary-800) !important;
            }

            .handle {
                visibility: visible;
                opacity: 1;
            }
        }

        &.active {
            .bar {
                fill: rgba(var(--fuse-text-secondary-rgb), var(--tw-text-opacity));
            }

            .bar-progress {
                fill: var(--fuse-primary-800) !important;
            }
        }
    }

    .lower-text,
    .upper-text {
        font-size: 12px;
        text-anchor: middle;
    }
    .upper-text {
        fill: var(--fuse-text-default) !important;
    }
    .lower-text {
        fill: var(--fuse-text-default) !important;
    }

    .hide {
        display: none;
    }
}

.gantt-container {
    position: relative;
    overflow: auto;
    font-size: 12px;

    .popup-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        padding: 0;
        color: #959da5;
        border-radius: 3px;

        .title {
            border-bottom: 3px solid $blue;
            padding: 10px;
        }

        .subtitle {
            padding: 10px;
            color: #dfe2e5;
        }

        .pointer {
            position: absolute;
            height: 5px;
            margin: 0 0 0 -5px;
            border: 5px solid transparent;
            border-top-color: rgba(0, 0, 0, 0.8);
        }
    }
}
