.nav-color {
    background-color: rgb(255, 255, 255);

    .dark & {
        background-color: rgb(41, 41, 41);
    }
}

.ms-teams-button {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    border-radius: 0 !important;

    background-color: inherit !important;
    color: rgb(113, 113, 113) !important;

    .dark & {
        background-color: inherit !important;
        color: rgb(198, 198, 198) !important;
    }
}
.ms-teams-button:hover {
    background-color: transparent !important;
    color: black !important;

    .dark & {
        background-color: transparent !important;
        color: rgb(255, 255, 255) !important;
    }

    border-bottom: rgb(224, 224, 224) 4px solid !important;
}

.ms-teams-selected {
    color: rgb(128, 133, 238) !important;
    .dark & {
        color: rgb(128, 133, 238) !important;
    }
    border-bottom: rgb(128, 133, 238) 4px solid !important;
}
.ms-teams-selected:hover {
    color: black !important;
    border-bottom: rgb(128, 133, 238) 4px solid !important;
}
