/* Do not use tailwind base for these styles, as it will override fuse styles */
h1,
h2,
h3,
h4,
h5,
h6,
.h0,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    @apply font-semibold;
}

.h0 {
    @apply leading-tight text-[2.5rem] sm:text-[3rem] md:text-[3.5rem] lg:text-[4rem];
}

h1,
.h1 {
    @apply leading-tight text-[2.5rem] md:text-[2.75rem] lg:text-[3rem];
}

h2,
.h2 {
    @apply leading-tight text-[2rem] md:text-[2.25rem] lg:text-[2.5rem];
}

h3,
.h3 {
    font-size: 1.875rem;
    /* 30px */
    line-height: 2.25rem;
    /* 36px */
}

h4,
.h4 {
    font-size: 1.5rem;
    /* 24px */
    line-height: 1.75rem;
    /* 28px */
}

h5,
.h5 {
    font-size: 1.25rem;
    /* 20px */
    line-height: 1.5rem;
    /* 24px */
}

h6,
.h6 {
    font-size: 1rem;
    /* 16px */
    line-height: 1.25rem;
    /* 20px */
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

// tailwind layer utilities
@layer utilities {
    // flex utilities
    .flex--row {
        @apply flex flex-row;
    }

    .flex--row--center {
        @apply flex--row items-center justify-center;
    }

    .flex--col {
        @apply flex flex-col;
    }

    .flex--col--center {
        @apply flex--col items-center justify-center;
    }

    // viewport containers for padding along x-axis
    .viewport-container {
        @apply px-[5%] sm:px-[7%];
    }

    .viewport-container--m {
        @apply viewport-container md:px-[10%];
    }

    .viewport-container--l {
        @apply viewport-container sm:px-[10%] md:px-[15%];
    }

    // normal containers for padding along y-axis
    .container-y--xs {
        @apply py-4;
    }

    .container-y--s {
        @apply py-6;
    }

    .container-y {
        @apply py-6 sm:py-7 md:py-8;
    }

    .container-y--m {
        @apply py-6 sm:py-8 md:py-10;
    }

    .container-y--l {
        @apply py-8 sm:py-11 md:py-14;
    }

    .container-y--xl {
        @apply py-8 sm:py-12 md:py-20;
    }

    // normal containers for padding along x-axis
    .container-x--xs {
        @apply px-4;
    }

    .container-x--s {
        @apply px-6;
    }

    .container-x {
        @apply px-6 sm:py-7 md:py-8;
    }

    .container-x--m {
        @apply px-6 sm:px-8 md:px-10;
    }

    .container-x--l {
        @apply px-8 sm:px-11 md:px-14;
    }

    // normal containers for padding across all sides
    .container--xs {
        @apply p-4;
    }

    .container--s {
        @apply p-6;
    }

    .container {
        @apply p-6 sm:py-7 md:py-8;
    }

    .container--m {
        @apply p-6 sm:p-8 md:px-10;
    }

    .container--l {
        @apply p-8 sm:p-11 md:p-14;
    }
}
