@use '@angular/material' as mat;
/* Default scaling for CKEditor buttons */

.ck.ck-editor {
    @apply text-sm scale-75 md:scale-90 xl:text-md xl:scale-100;
}

:root {
    @apply text-sm md:text-sm lg:text-md xl:text-lg;
}

.mat-mdc-tab-group .mat-mdc-tab-body-content {
    padding: 0.5rem !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.selectionBackground {
    // Type 0
    // background: url('assets/images/svg-flowchart-selection/light_0.svg') no-repeat bottom left;
    // background-size: 100% 100%;
    // Type 1
    background: url('assets/images/svg-flowchart-selection/light_1.svg') no-repeat bottom left;
    background-size: 100% 100%;
    // background: url('assets/images/svg-flowchart-selection/light_source_2.svg') no-repeat top right,
    //     url('assets/images/svg-flowchart-selection/light_1.svg') no-repeat bottom left;
    // background-size: 4%, 100% 100%;
    // Type 2.1
    // background: url('assets/images/svg-flowchart-selection/light_source.svg') no-repeat top right,
    //     url('assets/images/svg-flowchart-selection/light_2.svg') no-repeat bottom left;
    // background-size: 4%, 100% 100%;
    // Type 2.2
    // background: url('assets/images/svg-flowchart-selection/light_source_2.svg') no-repeat top right,
    //     url('assets/images/svg-flowchart-selection/light_3.svg') no-repeat bottom left;
    // background-size: 4%, 100% 100%;
    // Type 2.3
    // background: url('assets/images/svg-flowchart-selection/light_source_3.svg') no-repeat top right,
    //     url('assets/images/svg-flowchart-selection/light_3.svg') no-repeat bottom left;
    // background-size: 4%, 100% 100%;
    // Type 2.4
    // background: url('assets/images/svg-flowchart-selection/light_source_3.svg') no-repeat top right,
    //     url('assets/images/svg-flowchart-selection/light_2.svg') no-repeat bottom left;
    // background-size: 4%, 100% 100%;

    .dark & {
        background: url('assets/images/svg-flowchart-selection/dark_1.svg') no-repeat bottom left;
        background-size: 100% 100%;
        // background: url('assets/images/svg-flowchart-selection/dark_source.svg') no-repeat top right,
        //     url('assets/images/svg-flowchart-selection/dark.svg') no-repeat bottom left;
        // background-size: 4%, 100% 100%;
    }
}

/* Change the color of the scrollbar background */
// ::-webkit-scrollbar {
//     @apply bg-transparent;
//     @apply h-2 w-2;
// }

// /* Change the color of the scrollbar thumb */
// ::-webkit-scrollbar-thumb {
//     @apply bg-slate-400 dark:bg-slate-950;
//     @apply opacity-30;
//     @apply rounded-full;

//     .dark & {
//         @apply bg-slate-950;
//     }
// }
