// @import '@angular/material/theming';

/* -------------------------------------------------------------------------- */
/*  @ Tabs
/* -------------------------------------------------------------------------- */

.mat-mdc-tab-group {
    .mat-mdc-tab-header {
        .mat-mdc-tab-label-container {
            margin: 0px;
        }
    }

    .mat-mdc-tab-body-content {
        padding: 1rem;
    }
}

/* -------------------------------------------------------------------------- */
/*  @ Card
/* -------------------------------------------------------------------------- */
.mat-mdc-card {
    &.mdc-card {
        @apply shadow;
    }
    --mdc-elevated-card-container-shape: 1rem !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Tree
/* -------------------------------------------------------------------------- */
.mat-tree {
    white-space: nowrap;
    overflow: auto;
}

.mat-tree-node {
    --tree-node-min-height: 1.5rem;
    --icon-size: calc(var(--tree-node-min-height, 2rem) * 0.85);
    --checkbox-size: calc(var(--tree-node-min-height, 2rem) * 0.65);
    --mdc-icon-button-layer-size: var(--tree-node-min-height, 2rem);
    --mdc-checkbox-state-layer-size: var(--checkbox-size);

    @apply dark:bg-default;

    user-select: none;
    min-height: var(--tree-node-min-height) !important;
    margin-block: auto;
    font-size: 13px;

    // set icon size inside tree nodes
    .mdc-icon-button,
    .mat-mdc-icon-button,
    .mat-unthemed,
    .mat-mdc-button-base,
    .mat-mdc-button-touch-target {
        width: var(--mdc-icon-button-layer-size) !important;
        height: var(--mdc-icon-button-layer-size) !important;
        max-height: var(--mdc-icon-button-layer-size) !important;
        min-height: var(--mdc-icon-button-layer-size) !important;
    }
    .mat-icon {
        font-size: var(--icon-size);
    }

    // set checkbox size inside tree nodes
    .mat-mdc-checkbox-touch-target {
        height: var(--mdc-checkbox-state-layer-size) + 0.5rem;
        width: var(--mdc-checkbox-state-layer-size) + 0.5rem;
    }
    .mdc-checkbox__background {
        height: var(--checkbox-size);
        width: var(--checkbox-size);
    }

    &:hover {
        @apply bg-hover cursor-pointer;
    }

    &.selectedNode {
        background-color: var(--fuse-primary-200) !important;
        @apply dark:bg-[var(--fuse-primary-900)] #{!important};
    }

    &:nth-child(2n) {
        @apply bg-default;
        @apply dark:bg-[rgba(var(--fuse-bg-card-rgb))] #{!important};
    }
}

/* -------------------------------------------------------------------------- */
/*  @ Table
/* -------------------------------------------------------------------------- */
.mat-mdc-table-sticky {
    @apply bg-card;
    opacity: 1;
}

table.mat-mdc-table {
    --table-row-min-height: 2rem;
    --mdc-icon-button-layer-size: 1.5rem;
    --checkbox-size: calc(var(--tree-node-min-height, 2rem) * 0.75 * 0.65);
    --mdc-checkbox-state-layer-size: var(--checkbox-size);

    @apply bg-transparent #{!important};
    // @include mat-elevation(2);

    width: 100%;
    background: transparent;
    text-wrap: nowrap;

    // header row height
    .mdc-data-table__header-row {
        height: calc(var(--table-row-min-height) * 1.25);
    }

    // data row height
    .mdc-data-table__row {
        height: var(--table-row-min-height);
    }

    th.mat-sort-header-sorted {
        color: black;
    }

    .mdc-data-table__cell {
        border-color: rgba(0, 0, 0, 0.08) !important;
    }

    .mat-mdc-row {
        background: transparent;
    }
    .mat-mdc-cell {
        background: transparent;
    }
    .mdc-data-table__row:nth-child(2n) {
        @apply bg-default;
        @apply dark:bg-[rgba(var(--fuse-bg-card-rgb))] #{!important};
    }
    .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
        @apply cursor-pointer;
        @apply bg-hover #{!important};
    }
    .mdc-data-table__row:has(input[type='checkbox']:checked),
    .mdc-data-table__row.selected {
        background-color: var(--fuse-primary-200) !important;
        @apply dark:bg-[var(--fuse-primary-900)] #{!important};
    }

    // set icon size inside table
    .mdc-icon-button,
    .mat-mdc-icon-button,
    .mat-unthemed,
    .mat-mdc-button-base,
    .mat-mdc-button-touch-target {
        width: var(--mdc-icon-button-layer-size) !important;
        height: var(--mdc-icon-button-layer-size) !important;
        max-height: var(--mdc-icon-button-layer-size) !important;
        min-height: var(--mdc-icon-button-layer-size) !important;
    }

    // set checkbox size inside tree nodes
    .mat-mdc-checkbox-touch-target {
        height: var(--mdc-checkbox-state-layer-size) + 0.5rem;
        width: var(--mdc-checkbox-state-layer-size) + 0.5rem;
    }
    .mdc-checkbox__background {
        height: var(--checkbox-size);
        width: var(--checkbox-size);
    }
}

/* -------------------------------------------------------------------------- */
/*  @ Checkbox
/* -------------------------------------------------------------------------- */

/* Target the disabled and checked mat-checkbox */
.mat-checkbox-disabled.mat-checkbox-checked
    .mat-checkbox-inner-container
    .mat-checkbox-checkmark-path {
    /* Change the stroke color to a darker shade for better visibility */
    stroke: black;

    /* Increase the stroke width for a bolder checkmark */
    stroke-width: 2.5;
}

/* If you also want to adjust the background color of the checkbox when it's disabled and checked */
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
    background-color: rgba(0, 0, 0, 0.12); /* Adjust the alpha for desired opacity */
}

/* -------------------------------------------------------------------------- */
/*  @ Pagination
/* -------------------------------------------------------------------------- */
.mat-mdc-paginator {
    @apply bg-transparent #{!important};

    .mat-mdc-paginator-container {
        padding: 0px;
        justify-content: flex-end;

        .mat-mdc-paginator-page-size {
            margin: 0px;
        }

        .mat-mdc-paginator-range-actions {
            margin: 0px;
        }
    }
}

/* -------------------------------------------------------------------------- */
/*  @ Form fields
/* -------------------------------------------------------------------------- */
.mat-mdc-form-field.mat-form-field-appearance-fill
    .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mat-mdc-form-field-infix {
    min-height: 2rem !important;
}

.search-field {
    &.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
        border-width: 0px 0px 1px;
        border-radius: 0px;
        box-shadow: none !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}
