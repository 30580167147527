.cal-month-view {
    background-color: rgba(var(--fuse-bg-card-rgb), var(--tw-bg-opacity)) !important;
}
.cal-month-view .cal-cell-row:hover {
    background-color: rgba(var(--fuse-bg-default-rgb), var(--tw-bg-opacity)) !important;
}
.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: var(--fuse-primary-400) !important;
    .dark & {
        background-color: var(--fuse-primary-800) !important;
    }
}
.cal-month-view .cal-days {
    border-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
.cal-month-view .cal-day-cell:not(:last-child) {
    border-right-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
[dir='rtl'] .cal-month-view .cal-day-cell:not(:last-child) {
    border-right-color: initial;
    border-left-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
.cal-month-view .cal-days .cal-cell-row {
    border-bottom-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
.cal-month-view .cal-day-badge {
    // background-color: #b94a48;
    background-color: var(--fuse-primary) !important;
    color: #fff;
}
.cal-month-view .cal-event {
    background-color: #1e90ff;
    border-color: #d1e8ff;
    color: #fff;
}
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    // color: #8b0000;
    color: var(--fuse-warn-600) !important;
}
.cal-month-view .cal-day-cell.cal-today {
    background-color: var(--fuse-primary-300) !important;
    .dark & {
        background-color: var(--fuse-primary-700) !important;
    }
}
.cal-month-view .cal-day-cell.cal-drag-over {
    background-color: #e0e0e0 !important;
}
.cal-month-view .cal-open-day-events {
    color: var(--fuse-text-default) !important;
    background-color: rgba(var(--fuse-bg-default-rgb), var(--tw-bg-opacity)) !important;
    -webkit-box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
}

.cal-week-view {
    background-color: rgba(var(--fuse-bg-card-rgb), var(--tw-bg-opacity)) !important;
    border-top: solid 1px rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
.cal-week-view .cal-day-headers {
    border-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
    border-top: 0;
}
.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
    border-right-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
[dir='rtl'] .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
    border-right-color: initial;
    border-left: solid 1px rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
.cal-week-view .cal-day-headers .cal-header:first-child {
    border-left-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
[dir='rtl'] .cal-week-view .cal-day-headers .cal-header:first-child {
    border-left-color: initial;
    border-right-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
.cal-week-view .cal-day-headers .cal-header:hover,
.cal-week-view .cal-day-headers .cal-drag-over {
    background-color: var(--fuse-primary-400) !important;
    .dark & {
        background-color: var(--fuse-primary-800) !important;
    }
}
.cal-week-view .cal-day-column {
    border-left-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
[dir='rtl'] .cal-week-view .cal-day-column {
    border-left-color: initial;
    border-right-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
.cal-week-view .cal-event {
    background-color: #d1e8ff;
    border-color: #1e90ff;
    color: #1e90ff;
    border: 0px !important;
    border-left: 0.25rem solid !important;
}
.cal-week-view .cal-all-day-events {
    border-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
.cal-week-view .cal-header.cal-today {
    background-color: var(--fuse-primary-400) !important;
    .dark & {
        background-color: var(--fuse-primary-800) !important;
    }
}
.cal-week-view .cal-header.cal-weekend span {
    // color: #8b0000;
    color: var(--fuse-warn-600) !important;
}
.cal-week-view .cal-time-events {
    border-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
.cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
    background-color: var(--fuse-primary-400) !important;
    .dark & {
        background-color: var(--fuse-primary-800) !important;
    }
}
.cal-week-view .cal-hour-odd {
    background-color: rgba(var(--fuse-bg-default-rgb), var(--tw-bg-opacity)) !important;
}
.cal-week-view .cal-drag-over .cal-hour-segment {
    background-color: var(--fuse-primary-400) !important;
    .dark & {
        background-color: var(--fuse-primary-800) !important;
    }
}
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;
}
.cal-week-view .cal-current-time-marker {
    // background-color: #ea4334;
    background-color: var(--fuse-warn-800);
}

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
    border-top-color: #000;
}

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
    border-right-color: #000;
}

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
    border-bottom-color: #000;
}

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
    border-left-color: #000;
}

.cal-tooltip-inner {
    color: #fff;
    background-color: #000;
}
