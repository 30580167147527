.ck {
    .ck.ck-editor__main > .ck-editor__editable {
        white-space: pre-wrap; /* CSS3 */
        word-wrap: break-word; /* IE */
        overflow-wrap: anywhere; /* CSS3 */
    }

    :link,
    :visited {
        color: #0000ee;
        text-decoration: underline;
    }

    ol,
    ul {
        @apply pl-8;
    }

    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused).ck-read-only {
        --ck-color-base-border: transparent;
    }

    .dark & {
        .ck-toolbar {
            background: inherit;
            border: 1px solid var(--fuse-divider);
        }
        .ck-toolbar__separator {
            background: var(--fuse-divider);
        }
        .ck.ck-button,
        a.ck.ck-button {
            color: var(--fuse-text-default);
            &.ck-color-grid__tile {
                background: inherit;
            }
        }
        .ck.ck-editor__main > .ck-editor__editable {
            background: inherit;
        }

        .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused):not(.ck-read-only) {
            --ck-color-base-border: var(--fuse-divider);
        }

        .ck.ck-dropdown__panel,
        .ck.ck-list {
            background: var(--fuse-bg-dialog);
        }
        .ck.ck-button:not(.ck-disabled):active,
        a.ck.ck-button:not(.ck-disabled):active,
        .ck.ck-button:not(.ck-disabled):hover,
        a.ck.ck-button:not(.ck-disabled):hover,
        .ck.ck-button.ck-on,
        a.ck.ck-button.ck-on {
            background: var(--fuse-primary-900);
        }

        .ck.ck-labeled-field-view > .ck.ck-labeled-field-view__input-wrapper > .ck.ck-label,
        .ck.ck-input {
            background: var(--fuse-bg-dialog);
            color: var(--fuse-text-default);
        }
        .ck.ck-input:focus {
            box-shadow: var(--fuse-primary), 0 0;
        }

        // .ck.ck-balloon-panel.ck-powered-by-balloon

        &.ck-reset_all :not(.ck-reset_all-excluded *),
        .ck.ck-reset_all {
            color: var(--fuse-text-default);
        }

        --ck-color-split-button-hover-background: #1766a6;
    }
}

.ck-balloon-rotator__navigation,
.ck-balloon-panel.ck-powered-by-balloon,
.ck-balloon-panel {
    background: var(--fuse-bg-dialog) !important;
}

// do not display toolbar if the editor is in read only mode
.ck.ck-editor__top:has(+ .ck-editor__main .ck-read-only) {
    display: none;
}

.ck.ck-editor__editable_inline.ck-read-only {
    --ck-spacing-standard: 0px;
}
